import React from "react";
import { Row, Col } from "antd";
import FindMe from "./findMe";

const Footer = () => {
  return (
    <Row style={{ paddingTop: "50px" }}>
      <Col
        style={{
          // height: "150px",
          width: "100vw",
          backgroundColor: "white",
          padding: "40px 0",
          marginTop: "20px"
        }}
      >
        <FindMe />
      </Col>
    </Row>
  );
};

export default Footer;
