import React from 'react';
import { Tooltip, Row, Col, Typography } from "antd";
import { SocialIcon } from 'react-social-icons';
const { Title } = Typography;
const FindMe = () => {
  return (
    <>
    <Row justify="center">
      <Col style={{ backgroundColor: "white" }}>
        <Title level={3} style={{ textAlign: "center", marginBottom: "20px" }} className="disable-selection">
          Find me on:
        </Title>
      </Col>
    </Row>
    <Row justify="center">
      <Col>
      <a href="https://www.instagram.com/de_droommachine/" target="_blank" rel="noreferrer">
          <SocialIcon network="instagram" style={{ margin: "0 10px" }} />
        </a>
      </Col>
      <Col>
      <Tooltip title="Check back soon!">
          <SocialIcon network="youtube" style={{ margin: "0 10px" }} />
        </Tooltip>
      </Col>
      <Col>
      <Tooltip title="Check back soon!">
          <SocialIcon network="spotify" style={{ margin: "0 10px" }} />
        </Tooltip>
      </Col>
    </Row>
    </>
  );
}

export default FindMe;
