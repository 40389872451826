import React from "react";
import Loadable from "react-loadable";
import { Row, Col, Spin } from "antd";
// import { Link } from "gatsby";
import Layout from "../components/layout";
// import Image from "../components/image";
import SEO from "../components/seo";
// import DrumMachine from "../components/drumMachine";
// import ContentDrawer from "../components/contentDrawer";
// import FindMe from "../components/findMe";
import Footer from "../components/footer";
import EmbeddedControls from "../components/embeddedControls";

const AudioPlayer = Loadable.Map({
  loader: {
    AudioPlayer: () =>
      import("../components/audioPlayer"),
  },
  loading() {
    return (
      <Row>
        <Col style={{ margin: "25px 0" }}>
          <Spin />
        </Col>
      </Row>
    );
  },
  render(loaded, props) {
    let AudioPlayer = loaded.AudioPlayer.default;
    return <AudioPlayer {...props} />;
  },
});

const IndexPage = () => (
  <Layout>
    <SEO title="HOME" />
    <div
      style={{
        width: "100vw",
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ margin: "80px 0" }}>
      <h1 className="text-logo">
        <span>De</span> Droommachine
      </h1>
      <h2 className="text-subtitle">
        VIEZE BEATS
      </h2>
      </div>
      
      <AudioPlayer />
      {/* <LinkInBio /> */}
      {/* <FindMe /> */}
      {/* <ContentDrawer title="Contact me" /> */}
      <EmbeddedControls />
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
