import React, { useState } from 'react';
import { Drawer, Comment, Avatar, Typography, Divider } from 'antd';
import { SocialIcon } from 'react-social-icons';
const { Text, Title, Link } = Typography;

const LinkInBio = ({ title, open }) => {
  const openModal = open;
  const [visible, setVisible] = useState(openModal);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Link onClick={showDrawer} type="link" style={{ fontFamily: "Bangers", letterSpacing: "2px", fontSize: "18px", color: "white", position: "fixed", top: "40px", right: "40px" }}>
        Insta / Link In Bio >
      </Link>
      <Drawer
        title={title}
        closable
        onClose={onClose}
        visible={visible}
        bodyStyle={{ backgroundColor: "#efefef" }}
        width="320px"
      >
        <Title level={2} style={{ marginTop: "5px", marginBottom: "10px" }}>Link in bio
          <a href="https://www.instagram.com/de_droommachine/" target="_blank" rel="noreferrer">
            <SocialIcon network="instagram" style={{ width: "28px", height: "28px", marginLeft: "14px" }} />
          </a>
        </Title>
        <Comment
          author={<a href="https://www.instagram.com/de_droommachine/" target="_blank" rel="noreferrer">@de_droommachine</a>}
          avatar={
            <Avatar
              src="./profile.png"
              alt="De Droommachine"
            />
          }
          content={
            <>
              <p>Almost reached 100 followers of 'My Modbap List', a collection of modbap tunes curated by @de_droommachine <span role="img" aria-label="emoji">🙌</span></p>
              <img src="modbap.jpg" alt="My Modbap List on Spotify" style={{ width: "100%" }} />
              <p>
                <SocialIcon network="spotify" style={{ margin: "0", width: "14px", height: "14px" }} /> <a href="https://open.spotify.com/playlist/5RVUfFUbNl8N0s6i97D0np?si=4259c0572dc14f2b" target="_blank" rel="noreferrer">https://open.spotify.com/playlist/5RVUfFUbNl8N0s6i97D0np?si=4259c0572dc14f2b</a>
              </p>
              <Text style={{ fontSize: "10px", paddingTop: "20px", fontStyle: "italic" }}>
                July 15, 2021
              </Text>
            </>
          }
        />
        <Divider />
        <Comment
          author={<a href="https://www.instagram.com/de_droommachine/" target="_blank" rel="noreferrer">@de_droommachine</a>}
          avatar={
            <Avatar
              src="./profile.png"
              alt="De Droommachine"
            />
          }
          content={
            <>
              <p>
                Lekkere plaat van @wietpaulusma <br /><SocialIcon network="spotify" style={{ margin: "0", width: "14px", height: "14px" }} /> <a href="https://open.spotify.com/album/01tHbClro5sp4BelrWnLzH?si=TVZpvkaQQiCTPa5YZZanUQ" target="_blank" rel="noreferrer">https://open.spotify.com/album/01tHbClro5sp4BelrWnLzH?si=TVZpvkaQQiCTPa5YZZanUQ</a>
              </p>
              <Text style={{ fontSize: "10px", paddingTop: "20px", fontStyle: "italic" }}>
                June 12, 2021
              </Text>
            </>
          }
        />
        <Divider />
        <Comment
          author={<a href="https://www.instagram.com/de_droommachine/" target="_blank" rel="noreferrer">@de_droommachine</a>}
          avatar={
            <Avatar
              src="./profile.png"
              alt="De Droommachine"
            />
          }
          content={
            <>
              <p>
                Modbappin' by [moos] <br /><SocialIcon network="spotify" style={{ margin: "0", width: "14px", height: "14px" }} /> <a href="https://open.spotify.com/album/6eh6ZfqU44RO3WSOdgeIMf?si=iqp1HBOAStO7rhBfxito-Q" target="_blank" rel="noreferrer">https://open.spotify.com/album/6eh6ZfqU44RO3WSOdgeIMf?si=iqp1HBOAStO7rhBfxito-Q</a>
              </p>
              <Text style={{ fontSize: "10px", paddingTop: "20px", fontStyle: "italic" }}>
                March 12, 2021
              </Text>
            </>
          }
        />
        <Divider />
        <Comment
          author={<a href="https://www.instagram.com/de_droommachine/" target="_blank" rel="noreferrer">@de_droommachine</a>}
          avatar={
            <Avatar
              src="./profile.png"
              alt="De Droommachine"
            />
          }
          content={
            <>
              <p>
                Check out my Modbap Spotify playlist, a selection of modular synth-based boom-bap & hiphop beats <span role="img" aria-label="emoji">🔥</span> <span role="img" aria-label="emoji">🎛️</span> <br /><SocialIcon network="spotify" style={{ margin: "0", width: "14px", height: "14px" }} /> <a href="https://open.spotify.com/playlist/5RVUfFUbNl8N0s6i97D0np?si=4259c0572dc14f2b" target="_blank" rel="noreferrer">https://open.spotify.com/playlist/5RVUfFUbNl8N0s6i97D0np?si=4259c0572dc14f2b</a>
              </p>
              <Text style={{ fontSize: "10px", paddingTop: "20px", fontStyle: "italic" }}>
                Oct 13, 2020
              </Text>
            </>
          }
        />
      </Drawer>
    </>
  );
}

export default LinkInBio;
