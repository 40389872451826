import React from "react";
import PropTypes from "prop-types";
import WithLocation from "./withLocation.js";
import LinkInBio from "./linkInBio";

const EmbeddedControls = ({ search, slug }) => {
  const { linkinbio } = search;
  if (linkinbio === "true") {
    return (
      <LinkInBio open={true} />
    );
  } else {
    return (
      <LinkInBio open={false} />
    );
  };
};

EmbeddedControls.propTypes = {
  search: PropTypes.object,
};

export default WithLocation(EmbeddedControls);
